// Rotated & Flipped Icons
// -------------------------

.#{$fa-css-prefix}-rotate-90 {
  @include fa-icon-rotate(90deg, 1);
}
.#{$fa-css-prefix}-rotate-180 {
  @include fa-icon-rotate(180deg, 2);
}
.#{$fa-css-prefix}-rotate-270 {
  @include fa-icon-rotate(270deg, 3);
}

.#{$fa-css-prefix}-flip-horizontal {
  @include fa-icon-flip(-1, 1, 0);
}
.#{$fa-css-prefix}-flip-vertical {
  @include fa-icon-flip(1, -1, 2);
}
.#{$fa-css-prefix}-flip-both,
.#{$fa-css-prefix}-flip-horizontal.#{$fa-css-prefix}-flip-vertical {
  @include fa-icon-flip(-1, -1, 2);
}
